import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import B2B from './B2B';
import B2C from './B2C';
import NoMatch from './NoMatch';

export default class App extends Component {
    render() {
        return <BrowserRouter>
            <Switch>
                <Route path='/' exact component={B2C} />
                <Route path='/business' component={B2B} />
                <Route component={NoMatch} />
            </Switch>
        </BrowserRouter>;
    }
}
