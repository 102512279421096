import React, { useState } from 'react';
import { Link, animateScroll } from 'react-scroll';
import { Sticky } from 'react-sticky';

const Header = ({ b2c }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(open => {
      document.body.classList.toggle('locked');
      animateScroll.scrollToTop({
        delay: 0,
        duration: 300,
      });

      return !open;
    });
  };

  return (
    <Sticky topOffset={650}>
      {({ style, isSticky }) =>
        <header className={isSticky ? 'fixed' : 'static'} style={{ ...style, background: isSticky ? 'rgba(255,255,255,0.95)' : 'rgba(95, 95, 95, 0.5)' }}>
          <div className="container">
            <Link className="logo" to="app" spy={true} smooth={true} duration={500} offset={0} />
            <ul className="nav">
              <li>
                <Link activeClass="active" to="why" spy={true} smooth={true} duration={500} offset={0}>Почему мы</Link>
              </li>
              <li>
                <Link activeClass="active" to="how" spy={true} smooth={true} duration={500} offset={0}>{b2c ? 'Как начать использовать' : 'Как подключить'}</Link>
              </li>
              <li>
                <Link activeClass="active" to="info" spy={true} smooth={true} duration={500} offset={-20}>О продукте</Link>
              </li>
              <li>
                <Link activeClass="active" to="price" spy={true} smooth={true} duration={500} offset={-40}>{b2c ? 'Наши сервисы' : 'Цены'}</Link>
              </li>
              <li>
                <Link activeClass="active" to="feedback" spy={true} smooth={true} duration={500} offset={-20}>Получить доступ</Link>
              </li>
              <li>
                <a href="https://app.meconsultant.ru/" target="_blank" rel="noopener noreferrer" className="btn btn-sm">Войти</a>
              </li>
            </ul>
            <button onClick={toggleMenu} className={'hamburger-icon ' + (menuOpen ? 'active' : 'closed')}>
              <span className="line line-1" />
              <span className="line line-2" />
              <span className="line line-3" />
            </button>
          </div>
          <div className={'menu menu-' + (menuOpen ? 'open' : 'closed')}>
            <div className="background">
              <div className="portion" />
              <div className="portion" />
              <div className="portion" />
            </div>
            <div className="menu-content">
              <span className="logo" />
              <ul className="nav">
                <li>
                  <Link onClick={toggleMenu} activeClass="active" to="why" spy={true} smooth={true} duration={500} offset={0}>Почему мы</Link>
                </li>
                <li>
                  <Link onClick={toggleMenu} activeClass="active" to="how" spy={true} smooth={true} duration={500} offset={-30}>Как подключить</Link>
                </li>
                <li>
                  <Link onClick={toggleMenu} activeClass="active" to="info" spy={true} smooth={true} duration={500} offset={-30}>О продукте</Link>
                </li>
                <li>
                  <Link onClick={toggleMenu} activeClass="active" to="price" spy={true} smooth={true} duration={500} offset={-30}>Цены</Link>
                </li>
                <li>
                  <Link onClick={toggleMenu} activeClass="active" to="feedback" spy={true} smooth={true} duration={500} offset={-30}>Контакты</Link>
                </li>
                <li>
                  <a href="https://app.meconsultant.ru/" target="_blank" rel="noopener noreferrer" className="btn">Войти</a>
                </li>
              </ul>
              <Link onClick={toggleMenu} to="form" spy={true} smooth={true} offset={-90} duration={500} className="btn">Принять участие</Link>
              <div className="box-contact">
                <div>
                  <a href="mailto:teach@ancor.ru" className="email">teach@ancor.ru</a>
                </div>
              </div>
            </div>
          </div>
        </header>
      }
    </Sticky>
  );
}

export default Header;
