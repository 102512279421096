import React, { Component } from 'react';
import ga from 'react-ga';
import ym, { YMInitializer } from 'react-yandex-metrika';

const GA = 'UA-59806744-2';
const YANDEX = 67211662;

const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
};

export default class TrackPageView extends Component {
    componentDidMount() {
        ga.initialize(GA);
        ga.pageview(window.location.pathname);
        ym('hit', window.location.pathname);
    }

    render() {
        return <YMInitializer
            accounts={[YANDEX]}
            options={ymOptions}
            version="2"
        />;
    }
}

