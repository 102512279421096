import React, { Component, Fragment } from 'react';
import { Link } from 'react-scroll';
import { StickyContainer } from 'react-sticky';
import Collapse, { Panel } from 'rc-collapse';
import * as yup from 'yup';
import { fromPairs } from 'ramda';
import axios from 'axios';
import * as validator from 'class-validator';
import Masked from 'react-text-mask';
import { ToastContainer, toast } from 'react-toastify';
import cx from 'classnames';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header';

import './index.scss';
import why1 from './assets/img/why/1.svg';
import why2 from './assets/img/why/2.svg';
import why3 from './assets/img/why/3.svg';
import why4 from './assets/img/why/4.svg';
import why5 from './assets/img/why/5.svg';
import why6 from './assets/img/why/6.svg';
import how1 from './assets/img/how/1.png';
import how2 from './assets/img/how/2.png';
import how3 from './assets/img/how/3.png';
import ancor from './assets/img/ancor.png';
import TrackPageView from './TrackPageView';

Modal.setAppElement('#root');

const initForm = {
    name: '',
    email: '',
    phone: '',
    opd: false
};

class FeedbackForm extends Component {
    static defaultProps = {
        prefix: ''
    };

    render() {
        const { submitted, form, pending, errors, onChangeField, onSubmit, prefix, b2c } = this.props;

        return <form id={`${prefix}form`} className={cx("form", { submitted })} onSubmit={onSubmit}>
            <div className={cx("form-group", { 'has-error': errors.name })}>
                <label>Ваше имя</label>
                <input id={`${prefix}name`} name="name" placeholder="" value={form.name} onChange={e => onChangeField('name', e)} />
                <small>{ errors.name }</small>
            </div>
            <div className={cx("form-group", { 'has-error': errors.phone })}>
                <label>Номер телефона</label>
                <Masked
                    name='phone'
                    id={`${prefix}phone`}
                    mask={['7', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    placeholder="79123456789"
                    placeholderChar={'\u2000'}
                    keepCharPositions={false}
                    guide={true}
                    value={form.phone}
                    onChange={e => onChangeField('phone', e)}
                />
                <small>{ errors.phone }</small>
            </div>
            <div className={cx("form-group", { 'has-error': errors.email })}>
                <label>Email</label>
                <input id={`${prefix}email`} name="email" value={form.email} onChange={e => onChangeField('email', e)} />
                <div><small>{ errors.email }</small></div>
            </div>
            { b2c &&
                <div className={cx("form-checkbox", { 'has-error': errors.opd })}>
                    <input id={`${prefix}opd`} type="checkbox" name="opd" checked={!!form.opd} onChange={e => onChangeField('opd', e, true)} />
                    <label htmlFor={`${prefix}opd`}>Соглашаюсь с <a href='https://ancor.ru/privacy-policy/' rel='noopener noreferrer' target='_blank'>Политикой обработки персональных данных</a> и принимаю условия <a href='https://ancor.ru/agreement-oferta/oferta.pdf' rel='noopener noreferrer' target='_blank'>Договора-оферты</a></label>
                    <small>{ errors.opd }</small>
                </div>
            }
            <button className="btn" type="submit" disabled={pending}>Оставить заявку</button>
        </form>;
    }
}

class B2B extends Component {
    state = {
        form: initForm,
        errors: null,
        submitted: false,
        pending: false,
        success: false,
        feedbackModal: false,
        modalErrors: null,
        modalPending: false,
        modalSubmitted: false,
        modalForm: initForm
    };

    async componentDidMount() {
        const errors = await this.getValidateErrors(this.state.form);
        this.initErrors = errors;

        this.setState({ errors });
    }

    onSubmit = e => {
        e.preventDefault();

        this.setState({ submitted: true, pending: !this.state.errors });

        if (!this.state.errors) {
            axios.post('/api/requests', this.state.form)
                .then(() => {
                    this.setState({ form: initForm, errors: this.initErrors, submitted: false, pending: false, success: true });
                })
                .catch(() => {
                    this.setState({ pending: false });
                    toast.error('Не удалось отправить заявку');
                });
        }
    }

    onSubmitModal = e => {
        e.preventDefault();

        this.setState({ modalSubmitted: true, modalPending: !this.state.modalErrors });

        if (!this.state.modalErrors) {
            axios.post('/api/requests', this.state.modalForm)
                .then(() => {
                    toast.success('Мы свяжемся с вами в течение 1 часа');
                    this.closeFeedbackModal();
                })
                .catch(() => {
                    this.setState({ modalPending: false });
                    toast.error('Не удалось отправить заявку');
                });
        }
    }

    getValidateErrors = async (values) => {
        const schema = yup.object().shape({
            name: yup.string().required('Это поле обязательно для заполнения'),
            phone: yup.string().test({
                name: 'phone',
                exclusive: false,
                message: 'Введите телефон в формате: 79123456789',
                test: (value) => value ? validator.isMobilePhone(value, 'ru-RU') : true
            }).required('Это поле обязательно для заполнения'),
            email: yup.string().email('Неверный формат email').required('Это поле обязательно для заполнения'),
            opd: this.props.b2c ? yup.boolean().oneOf([true], 'Это поле обязательно для заполнения') : yup.boolean().nullable()
        });

        try {
            await schema.validate(values, { abortEarly: false });

            return null;
        } catch (e) {
            const errors = fromPairs(e.inner.map(error => ([ error.path, error.message ])));

            return errors;
        }
    }

    onChangeField = async (name, e, checkbox) => {
        const value = checkbox ? e.target.checked : e.target.value;
        const form = {
            ...this.state.form,
            [name]: value
        };
        const errors = await this.getValidateErrors(form);

        this.setState({
            form,
            errors
        });
    }

    onChangeModalField = async (name, e, checkbox) => {
        const value = checkbox ? e.target.checked : e.target.value;
        const modalForm = {
            ...this.state.modalForm,
            [name]: value
        };
        const modalErrors = await this.getValidateErrors(modalForm);

        this.setState({
            modalForm,
            modalErrors
        });
    }

    openFeedbackModal = async () => {
        const modalErrors = await this.getValidateErrors(this.state.modalForm);
        this.modalInitErrors = modalErrors;

        this.setState({ modalErrors, feedbackModal: true });
    }

    closeFeedbackModal = () => {
        this.setState({ feedbackModal: false, modalForm: initForm, modalErrors: null,  modalSubmitted: false, modalPending: false });
    }

    render() {
        const { form, submitted, success, pending, modalForm, modalSubmitted, modalPending } = this.state;
        const { b2c } = this.props;
        const errors = this.state.errors || {};
        const modalErrors = this.state.modalErrors || {};

        return (
            <StickyContainer id="app">
                <Header b2c={b2c} />
                <section className="main-box">
                    <div className="container">
                        <h1>{ b2c ? 'MeConsultant – это личный помощник в поиске новой работы!' : 'MeConsultant – это профессиональная поддержка ваших сотрудников в поиске новой работы' }</h1>
                        <Link className="btn" activeClass="active" to="feedback" spy={true} smooth={true} duration={500}>Оставить заявку</Link>
                    </div>
                </section>
                <section id="why" className="why-box">
                    <div className="container">
                        <h2>Чем полезен MeConsultant?</h2>
                        <div className="row">
                            <div className="col-3">
                                <div className="item">
                                    <img src={why1} alt="" />
                                    <h3>Обучает навыкам поиска работы</h3>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="item">
                                    <img src={why2} alt="" />
                                    <h3>Содержит актуальную информацию по рынку труда</h3>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="item">
                                    <img src={why3} alt="" />
                                    <h3><span>Интегрирован с работным сайтом <a href="https://hh.ru" target="_blank" rel="noopener noreferrer">hh.ru</a></span></h3>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="item">
                                    <img src={why4} alt="" />
                                    <h3>Тренирует навыки прохождения интервью в формате видео-диалога</h3>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="item">
                                    <img src={why5} alt="" />
                                    <h3>Структурирует поиск работы и не дает расслабиться – стимулирует к активным действиям</h3>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="item">
                                    <img src={why6} alt="" />
                                    <h3>Быстро отвечает на все вопросы с помощью чата</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="how" className="how-box">
                    <div className="container">
                        <h2>{ b2c ? 'Как начать использовать MeConsultant?' : 'Как подключить сотрудников к MeConsultant?' }</h2>
                        <div className="row">
                            <div className="col-2">
                                { b2c ?
                                    <p>Если Вы решили использовать MeConsultant, то сделать это очень просто. <Link to="feedback" spy={true} smooth={true} duration={500}>Заполните форму заявки</Link>, и мы оперативно свяжемся с Вами и расскажем, как получить доступ</p> :
                                    <p>Если Вы решили предоставить своим сотрудникам возможность использовать MeConsultant, то сделать это очень просто. <Link to="feedback" spy={true} smooth={true} duration={500}>Заполните форму заявки</Link> и мы оперативно свяжемся с Вами и организуем доступ.</p>
                                }
                            </div>
                            <div className="col-2">
                                <img className="float" src={how1} alt="" />
                            </div>
                            <div className="col-2">
                                <img className="float" src={how2} alt="" />
                            </div>
                            <div className="col-2">
                                { b2c ?
                                    <p>Мы рекомендуем минимальный срок доступа на 1 календарный месяц. В некоторых случаях, срок может быть продлен до 3-х месяцев. Наши карьерные консультанты будут рады ответить на Ваши вопросы и определить оптимальный срок пользования порталом</p> :
                                    <p>Мы рекомендуем минимальный срок доступа для сотрудника на 1 календарный месяц. В некоторых случаях, срок может быть продлен до 3-х месяцев. Наши консультанты по Outplacement будут рады ответить на Ваши вопросы и определить оптимальный срок пользования порталом.</p>
                                }
                            </div>
                            <div className="col-2">
                                { b2c ?
                                    <p>Вы можете воспользоваться сервисом MeConsultant и с ПК, и с мобильных устройств. Регистрация проста и удобна, нужно ввести свои данные и код, который мы Вам предоставим</p> :
                                    <p>Ваши сотрудники могут воспользоваться сервисом MeConsultant и с ПК, и с мобильных устройств. Регистрация проста и удобна, нужно ввести свои данные и код, который мы предоставляем каждому пользователю портала.</p>
                                }
                            </div>
                            <div className="col-2">
                                <img className="float" src={how3} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="info" className="info-box">
                    <div className="container">
                        <h2>Какую информацию содержит MeConsultant?</h2>
                        <div className="copy">Данный сервис был разработан международной стаффинговой группой <a href="https://ancor.ru/">ANCOR</a></div>
                        <h3>Подготовка к поиску работы</h3>
                        <Collapse accordion={true}>
                            <Panel header="Этапы поиска работы">В этом разделе содержится информация об основных этапах поиска работы, действиях, которые нужно совершить на каждом из этапов, важные моменты, на которые нужно обращать внимание.</Panel>
                            <Panel header="Самоопределение">В этом разделе находятся упражнения на анализ своих сильных сторон, определение желаемой позиции, компании, в которой хочется работать, на понимание своих ожиданий и мотивации.</Panel>
                            <Panel header="Резюме и сопроводительные письма">Здесь находится информация о том, как правильно составить резюме и подготовить сопроводительные письма.</Panel>
                            <Panel header="Стратегия поиска работы">Очень важный раздел, в котором пользователь найдет актуальную информацию по рынку труда Москвы и регионов РФ, данные по работодателям, рейтинг привлекательности компаний-работодателей по всем отраслям, критерии выбора работодателей кандидатами, а также информация о том, как построить стратегию поиска новой работы.</Panel>
                        </Collapse>
                        <h3>Поиск работы</h3>
                        <Collapse accordion={true}>
                            <Panel header="Инструменты поиска работы">В этом разделе собрана информация обо всех инструментах и каналах поиска работы, о том, какие инструменты и в каких случаях использовать, как именно ими пользоваться.</Panel>
                            <Panel header="Интервью">Очень полезный раздел, который поможет пользователю подготовиться к интервью, познакомиться с методикой проведения интервью и потренироваться в его прохождении. Здесь есть формы, шаблоны, видеоматериалы и тренажер навыков прохождения интервью.</Panel>
                        </Collapse>
                        <h3>Завершение поиска работы</h3>
                        <Collapse accordion={true}>
                            <Panel header="Анализ предложений и выбор работодателя">Здесь содержится полезная информация и упражнение для анализа поступивших предложений от работодателей и выбора из них наиболее подходящего под цели и ожидания соискателя.</Panel>
                            <Panel header="Завершение поиска работы">В этом разделе находится информацию о том, как правильно завершить поиск работы, какие действия необходимо совершить, чтобы, во-первых, в последствии использовать наработанный материал и контакты, а во-вторых, не допустить неловких и неэтичных ситуаций.</Panel>
                        </Collapse>
                    </div>
                </section>
                <section id="price" className="price-box">
                    <div className="container">
                        <h2>{b2c ? 'Стоимость услуг' : 'Наши сервисы'}</h2>
                        <div className="price-list">
                            <div className="price-item">
                                <div className="desc">
                                    { b2c ?
                                        <h3>«Базовый»</h3> :
                                        <h3>Пакет услуг<br/> «Базовый»</h3>
                                    }
                                    <ul>
                                        <li><span>Доступ к материалам портала</span></li>
                                        <li><span>Профессиональная поддержка карьерного консультанта через онлайн ЧАТ</span></li>
                                    </ul>
                                </div>
                                { b2c && <div className="count" onClick={this.openFeedbackModal}>2 500 руб/мес</div> }
                            </div>
                            <div className="price-item">
                                <div className="desc">
                                    { b2c ?
                                        <h3>«Оптимальный»</h3> :
                                        <h3>Пакет услуг<br/> «Оптимальный»</h3>
                                    }
                                    <ul>
                                        <li><span>Доступ к материалам портала</span></li>
                                        <li><span>Профессиональная поддержка карьерного консультанта через онлайн ЧАТ</span></li>
                                        <li><span>Составление резюме</span></li>
                                        <li><span>Создание аккаунтов на самых востребованных работных сайтах</span></li>
                                    </ul>
                                </div>
                                { b2c && <div className="count" onClick={this.openFeedbackModal}>6 000 руб/мес</div> }
                            </div>
                            <div className="price-item">
                                <div className="desc">
                                    { b2c ?
                                        <h3>«Максимальный»</h3> :
                                        <h3>Пакет услуг<br/> «Максимальный»</h3>
                                    }
                                    <ul>
                                        <li><span>Доступ к материалам портала</span></li>
                                        <li><span>Профессиональная поддержка карьерного консультанта через онлайн ЧАТ</span></li>
                                        <li><span>Составление резюме</span></li>
                                        <li><span>Создание аккаунтов на самых востребованных работных сайтах</span></li>
                                        <li><span>Подготовка к интервью с работодателем</span></li>
                                    </ul>
                                </div>
                                { b2c && <div className="count" onClick={this.openFeedbackModal}>10 000 руб/мес</div> }
                            </div>
                        </div>
                        { b2c && <p>Указаны цены за предоставление доступа на портал для 1 пользователя сроком на 30 дней, без учета НДС.</p> }
                    </div>
                    <Modal
                        isOpen={this.state.feedbackModal}
                        onRequestClose={this.closeFeedbackModal}
                        style={{
                            content : {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)'
                            }
                        }}
                        contentLabel='Получить доступ'
                    >
                        <button type='button' className='modal-close' onClick={this.closeFeedbackModal}>✖</button>
                        <h2>Получить доступ</h2>
                        <FeedbackForm
                            form={modalForm}
                            errors={modalErrors}
                            pending={modalPending}
                            submitted={modalSubmitted}
                            onChangeField={this.onChangeModalField}
                            onSubmit={this.onSubmitModal}
                            b2c={b2c}
                            prefix='modal-' />
                    </Modal>
                </section>
                <section id="feedback" className="feedback-box">
                    <div className="container">
                        <h2>Получить доступ</h2>
                        <div className="row">
                            { !success ?
                                <Fragment>
                                    <div className="col-2">
                                        <FeedbackForm
                                            form={form}
                                            errors={errors}
                                            pending={pending}
                                            submitted={submitted}
                                            onChangeField={this.onChangeField}
                                            onSubmit={this.onSubmit}
                                            b2c={b2c} />
                                    </div>
                                    <div className="col-2">
                                        <p>Заполните форму заявки и мы свяжемся с Вами в ближайшее время.</p>
                                    </div>
                                </Fragment> :
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    Заявка успешно отправлена
                                </div>
                            }
                        </div>
                    </div>
                </section>
                <footer>
                    <div className="container">
                        <span>MeConsultant является собственностью ООО «АНКОР Консалтинг»</span> <a href="http://www.ancor-consulting.ru"><img src={ancor} alt="" /></a>
                    </div>
                </footer>
                <ToastContainer />
                { process.env.NODE_ENV === 'production' && <TrackPageView /> }
            </StickyContainer>
        );
    }
}

export default B2B;
